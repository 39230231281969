import Image from "./Image";

const Experience = ({ data }) => {
	const companyExperiences = data.workExperience.reduce(
		(result, experience) => {
			const { companyName, companyLogo, companyLink, location } =
				experience;

			// Find the existing company object in the result array
			const existingCompany = result.find(
				(company) => company.companyName === companyName
			);

			if (existingCompany) {
				// Add the experience to the existing company object
				existingCompany.experiences.push(experience);
			} else {
				// Create a new company object with the first experience
				const newCompany = {
					companyName,
					companyLogo,
					companyLink,
					location,
					experiences: [experience],
				};
				result.push(newCompany);
			}

			return result;
		},
		[]
	);

	return (
		<section
			className="section experience-section border-d timeline-block"
			id="experience"
		>
			<div className="row section-block experience-block">
				<div className="section-header">
					<h2>
						My <strong className="color">Experience</strong>
					</h2>
				</div>

				<ul className="timeline">
					{companyExperiences.map((work, idx) => (
						<li key={idx}>
							<h3>
								<a
									href={work.companyLink}
									target="_blank"
									rel="noreferrer"
									style={{ color: "inherit" }}
								>
									{work.companyLogo && (
										<Image
											src={work.companyLogo}
											alt={work.companyName}
											style={{
												width: "2em",
												height: "2em",
												marginRight: "1em",
												objectFit: "contain",
											}}
										/>
									)}
									{work.companyName}
								</a>
							</h3>
							{work.experiences.map((experience, idx) => (
								<div
									className="timeline-content"
									style={{ margin: "2rem 0" }}
									key={idx}
								>
									<em key={idx}>
										<span>{experience.position}</span>
										{experience.startDate &&
										experience.endDate ? (
											<span>
												{experience.startDate}-
												{experience.endDate}
											</span>
										) : experience.duration ? (
											<span>
												{" "}
												- {experience.duration}
											</span>
										) : null}
										{experience.skills && (
											<div style={{ marginTop: "1em" }}>
												Skills:
												{experience.skills
													.split("Skills:")[1]
													.trim()
													.split("·")
													.map((skill, idx) => (
														<span
															key={idx}
															className="badge text-bg-primary"
															style={{
																marginLeft:
																	"5px",
															}}
														>
															{skill}
														</span>
													))}
											</div>
										)}
									</em>
									<p>{work.description}</p>
								</div>
							))}
						</li>
					))}
				</ul>
			</div>
		</section>
	);
};
export default Experience;
